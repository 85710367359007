<template>
  <div class="home_container">
    <div class="banber_swiper">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in banner" :key="index">
            <img :src="item.image" alt="" class="banber" @click="href(item.jump_url)">
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
	  <div class="width1200">
	  	<div class="fixed">
	  		<h1>国内专业心理服务平台</h1>
	  		<h2>心理咨询师，只提供高质量的心理服务</h2>
			<div class="btnlist">
				<router-link to="/consultingService?type=1" tag="p" class="btn">心理咨询</router-link>
				<router-link to="/consultingService?type=2" tag="p" class="btn">精神科顾问</router-link>
			</div>
	  	</div>
	  </div>
    </div>
    <div class="service_box">
      <div class="service_inner m_1200">
        <h5 class="title">心理服务</h5>
        <p class="desc">针对 情绪压力/亲密关系/性心理/亲子教育/个人成长 等不同领域<br>提供个人化专业定制服务</p>
        <ul class="list_wrap">
          <li class="item" @click="hrefIn('/news?cid=14')">
            <div class="top">
              <div class="left">
                <p class="item_title">心理新闻</p>
                <p class="text">大数据心理新闻。</p>
              </div>
              <img src="../assets/images/home_photo1.png" alt="" class="photo">
            </div>
            <p class="texts">帮你分析增加的情绪状态</p>
          </li>
          <li class="item" @click="href('http://cp.sgdj.org.cn/')">
            <div class="top">
              <div class="left">
                <p class="item_title">心理评测</p>
                <p class="text">专业心理测评</p>
              </div>
              <img src="../assets/images/home_photo2.png" alt="" class="photo">
            </div>
            <p class="texts">帮你了解自己的心理状态</p>
          </li>
          <li class="item" @click="hrefIn('/consultingService?type=1')">
            <div class="top">
              <div class="left">
                <p class="item_title">心理咨询</p>
                <p class="text">多种领域服务</p>
              </div>
              <img src="../assets/images/home_photo3.png" alt="" class="photo">
            </div>
            <p class="texts">帮你咨询自己的心理状态</p>
          </li>
          <li class="item" @click="qcode">
            <div class="top">
              <div class="left">
                <p class="item_title">心理问答</p>
                <p class="text">在线提问</p>
              </div>
              <img src="../assets/images/home_photo4.png" alt="" class="photo">
            </div>
            <p class="texts">遇到的心理问题和困惑</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="consult_box">
      <div class="consult_inner m_1200">
        <h5 class="title">专业咨询师</h5>
        <p class="desc">平台专业监管下提供服务<br>咨询师都经过专业的系统训练，是国内Top1%的心理咨询师</p>
        <div class="certify">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in consultant" :key="index"><img @click="$router.push(`/doctorDetail?id=${item.id}`)" :src="item.avatar" /></div>
            </div>
          </div>
        </div>
        <div class="content_wrap">
          <div class="content_item" v-if="realIndex  === 0">
            <p class="name">{{firstInfo.name}}</p>
			<p class="text">{{firstInfo.experience}}</p>
          </div>
		 <div class="content_item" v-if="realIndex  === 1">
		    <p class="name">{{twoInfo.name}}</p>
		    <p class="text">{{twoInfo.experience}}</p>
		  </div>
		  <div class="content_item" v-if="realIndex  === 2">
		    <p class="name">{{threeInfo.name}}</p>
		    <p class="text">{{threeInfo.experience}}</p>
		  </div>
		  <div class="content_item" v-if="realIndex  === 3">
		    <p class="name">{{fourInfo.name}}</p>
		    <p class="text">{{fourInfo.experience}}</p>
		  </div>
		  <div class="content_item" v-if="realIndex  === 4">
		    <p class="name">{{fiveInfo.name}}</p>
		    <p class="text">{{fiveInfo.experience}}</p>
		  </div>
        </div>
        <router-link to="/consultingService?type=1" tag="p" class="all">全部咨询师</router-link>
      </div>
    </div>
    <div class="curriculum_box">
      <div class="consult_inner m_1200">
        <h5 class="title">心理课程</h5>
        <p class="desc">不仅仅是心理咨询，你可以在云家心理找到多样性的心理健康课程</p>
        <div class="curriculum">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in newsListLen" :key="index">
                <div class="list_wrap">
                  <router-link :to="'/knowledgeDetail?id=' + child.id+'&type=2'" class="item" v-for="(child,childIndex) in newsList" :key="childIndex">
                    <img :src="child.img" alt="" class="photo">
                    <div class="content_wrap">
                      <div class="content">{{child.title}}</div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
		  
        </div>
        <div class="swiper_pagination">
          <span class="item" v-for="(item,index) in newsListLen" :key="index" :class="{ active: realIndexOne === index }"></span>
        </div>
		<router-link to="/news?cid=10" tag="p" class="all" style="color: #999999;
		font-size: 20px;
		text-align: center;
		font-weight: 500;
		margin-top: 20px;
		cursor: pointer;">全部课程</router-link>
      </div>
    </div>
    <div class="consulting_service_box">
      <div class="consulting_service_inner m_1200">
        <h5 class="title">心理咨询</h5>
        <p class="desc">有关心理咨询，你需要知道这些</p>
        <div class="list_wrap">
          <div class="item">
            <p class="label">咨询前</p>
            <router-link :to="'/knowledgeDetail?id=' + item.id" class="text" v-for="(item,index) in newsList1" :key="index">{{item.title}}</router-link>
            <p class="text">更多...</p>
          </div>
          <div class="icon"></div>
          <div class="item">
            <p class="label">接受咨询中</p>
            <router-link :to="'/knowledgeDetail?id=' + item.id" class="text" v-for="(item,index) in newsList2" :key="index">{{item.title}}</router-link>
            <p class="text">更多...</p>
          </div>
          <div class="icon"></div>
          <div class="item">
            <p class="label">完成咨询后</p>
            <router-link :to="'/knowledgeDetail?id=' + item.id" class="text" v-for="(item,index) in newsList3" :key="index">{{item.title}}</router-link>
            <p class="text">更多...</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card_box m_1200">
      <div class="card_inner">
        <div class="card_item" v-for="(item,index) in iconlist" :key="index">
          <img :src="item.img" alt="" class="photo">
          <p class="title">{{item.title}}</p>
          <p class="content">{{item.describe}}</p>
        </div>
      </div>
    </div>
    <div class="dowload_box">
      <div class="download_inner m_1200">
        <img :src="phone1" alt="" class="photo1">
        <img :src="phone2" alt="" class="photo2">
        <div class="code_wrap">
          <div class="title">云家心理</div>
          <p class="desc">心理量表购买请关注二维码，点击心理店铺</p>
          <div class="wrap">
            <div class="item">
              <img src="../assets/images/home_photo17.png" alt="">
              <p class="text">云家心理公众号</p>
            </div>
            <!-- <div class="item">
              <img src="../assets/images/home_photo18.png" alt="">
              <p class="text">联析小程序</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="partners_box">
      <div class="partners_inner m_1200">
        <h5 class="title">合作伙伴</h5>
        <p class="desc">不仅仅是心理咨询，你可以在云家心理找到多样性的心理健康课程</p>
        <div class="list_wrap">
          <a :href="item.url" target="_blank" v-for="(item,index) in partnerList" :key="index" class="photo"><img :src="item.icon" alt=""></a>
        </div>
		<router-link to="/partner" tag="p" class="all" style="color: #999999;
		font-size: 20px;
		text-align: center;
		font-weight: 500;
		margin-top: 20px;
		cursor: pointer;">全部</router-link>
      </div>
    </div>
	<el-dialog title="心理咨询" :visible.sync="isCode" width="400px">
	    <img src="../assets/images/code.jpg" alt="" style="width: 100%;">
	  <span slot="footer" class="dialog-footer">
	    <el-button type="primary" @click="isCode = false">关 闭</el-button>
	  </span>
	</el-dialog>
  </div>
</template>

<script>
let _this
export default {
  name: 'Home',
  data () {
    _this = this
    return {
		banner:[],
		consultant:[],
		newsList:[],
		newsList1:[],
		newsList2:[],
		newsList3:[],
		realIndex: 0,//当前3d轮播图下标
		realIndexOne: 0,//当前心里课程轮播图下标
		partnerList:[],
		firstInfo:'',
		twoInfo:"",
		threeInfo:'',
		fourInfo:'',
		fiveInfo:'',
		newsListLen:1,
		isCode:false,
		iconlist:[],
		phone1:'',
		phone2:''
    }
  },
  mounted () {
	  // 轮播图
    this.$http({
      method: 'POST',
      url: '/Banner/banner',
      data: {}
    }).then((res)=>{
		this.banner=res.lists
	})
	this.$http({
	  method: 'POST',
	  url: '/ad/ad',
	  data: {cate_id:3}
	}).then((res)=>{
		this.iconlist=res.lists
	})
	this.$http({
	  method: 'POST',
	  url: '/ad/ad',
	  data: {cate_id:2}
	}).then((res)=>{
		this.phone1=res.lists[0].img
		this.phone2=res.lists[1].img
	})
	// 咨询师
	this.$http({
	  method: 'POST',
	  url: '/Other/getConsultant',
	  data: {cate:1}
	}).then((res)=>{
		this.consultant=res.data.length>5?res.data.splice(0,5):res.data
		console.log(this.consultant[0],999)
		if(this.consultant.length==1){
			this.firstInfo=this.consultant[0]
		}
		if(this.consultant.length==2){
			this.firstInfo=this.consultant[0]
			this.twoInfo=this.consultant[1]
		}
		if(this.consultant.length==3){
			this.firstInfo=this.consultant[0]
			this.twoInfo=this.consultant[1]
			this.threeInfo=this.consultant[2]
		}
		if(this.consultant.length==4){
			this.firstInfo=this.consultant[0]
			this.twoInfo=this.consultant[1]
			this.threeInfo=this.consultant[2]
			this.fourInfo=this.consultant[3]
		}
		if(this.consultant.length==5){
			this.firstInfo=this.consultant[0]
			this.twoInfo=this.consultant[1]
			this.threeInfo=this.consultant[2]
			this.fourInfo=this.consultant[3]
			this.fiveInfo=this.consultant[4]
		}
	})
	this.$http({
	  method: 'POST',
	  url: '/News/news',
	  data: {cate_id:10,pageIndex:1,pageSize:16}
	}).then((res)=>{
		this.newsList=res.data
		var newsListLen=res.data.length/8
		if(newsListLen<1){
			newsListLen=1
		}else{
			newsListLen=Math.ceil(newsListLen)
		}
	})
	// 咨询分类
	this.$http({
	  method: 'POST',
	  url: '/News/news',
	  data: {cate_id:1,pageIndex:1,pageSize:4}
	}).then((res)=>{
		this.newsList1=res.data.splice(0,4)
	})
	this.$http({
	  method: 'POST',
	  url: '/News/news',
	  data: {cate_id:2,pageIndex:1,pageSize:4}
	}).then((res)=>{
		this.newsList2=res.data.splice(0,4)
	})
	this.$http({
	  method: 'POST',
	  url: '/News/news',
	  data: {cate_id:3,pageIndex:1,pageSize:4}
	}).then((res)=>{
		this.newsList3=res.data.splice(0,4)
	})
	// 合作伙伴
	this.$http({
	  method: 'POST',
	  url: '/other/getPartner',
	  data: {}
	}).then((res)=>{
		this.partnerList=res.partner
	})
    this.initSwiper()
  },
  methods: {
	  qcode(){
		  this.isCode=true
	  },
	  hrefIn(url){
		  this.$router.push(url)
	  },
	  href(url){
		  if(url.indexOf("http") != -1){
			  window.open(url)
		  }
	  },
	  detail(id,type){  
		  if(type==2){
			  this.$router.push({name:'knowledgeDetail',query:{id:id,type:2}})
		  }else{
			 this.$router.push({name:'knowledgeDetail',query:{id:id}}) 
		  }
	  },
    //初始化轮播图
    initSwiper () {
      this.$nextTick(() => {
        new Swiper('.certify .swiper-container', {
          watchSlidesProgress: true,
          slidesPerView: 'auto',
          centeredSlides: true,
          loop: true,
          loopedSlides: 5,
          autoplay: true,
          on: {
            progress: function(progress) {
              for (var i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i);
                var slideProgress = this.slides[i].progress;
                var modify = 1;
                if (Math.abs(slideProgress) > 1) {
                  modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                }
                var translate = slideProgress * modify * 260 + 'px';
                var scale = 1 - Math.abs(slideProgress) / 5;
                var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                slide.css('zIndex', zIndex);
                slide.css('opacity', 1);
                if (Math.abs(slideProgress) > 3) {
                  slide.css('opacity', 0);
                }
                _this.realIndex = this.realIndex
              }
            },
            slideChangeTransitionEnd () {
              _this.realIndex = this.realIndex
            },
            sliderMove () {
              _this.realIndex = this.realIndex
            },
            setTransition: function(swiper, transition) {
              _this.realIndex = this.realIndex
              for (var i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i)
                slide.transition(transition);
              }

            }
          }
        })
        new Swiper('.curriculum .swiper-container', {
          loop: true,
          autoplay: false,
          pagination: {
            el: '.swiper-pagination'
          },
          on: {
            progress: function(progress) {
              _this.realIndexOne = this.realIndex
            },
            slideChangeTransitionEnd () {
              _this.realIndexOne = this.realIndex
            },
            sliderMove () {
              _this.realIndexOne = this.realIndex
            },
            setTransition: function(swiper, transition) {
              _this.realIndexOne = this.realIndex
            }
          }
        })
        new Swiper('.banber_swiper .swiper-container', {
          loop: true,
          autoplay: true,
          pagination: {
            el: '.swiper-pagination'
          }
        })
      })
    }
  }
}
</script>

<style lang="scss">
.home_container {
	position: relative;
  .banber_swiper {
    display: block;
    width: 100%;
    height: 578px;
	 position: relative;
	 .banber{
		width: 100%;
		height: 578px;
	 }
    .swiper-container {
      position: relative;
    }
	.width1200{
		width: 1200px;
		margin: 0 auto;
		z-index: 99;
		position: relative;
		margin-top: -430px;
	}
	.fixed{
		position: absolute;
		top: 10%;
		right: 0%;
		margin:auto;
		width:360px;
		z-index: 99;
		background:rgba(255,255,255,0.5);
		border-radius: 10px;
		text-align: center;
		padding-bottom: 10px;
		color: #E51418;
		h1{
			margin-top: 30px;
			font-size: 20px;
		}
		h2{
			margin-top: 20px;
			font-size: 16px;
		}
		.btnlist{
			display: flex;
			width: 90%;
			margin: 0 auto;
			justify-content: space-between;
			.btn{
				width: 45%;
				background-color: #E51418;
				line-height: 40px;
				color: #fff;
				margin-top: 40px;
				cursor: pointer;
				border-radius: 5px;
			}
		}
	}
  }
  .service_box {
    padding: 70px 0 112px;
    background-color: #f5f5f5;
    .service_inner {
      .title {
        font-size: 36px;
        font-weight: bold;
        text-align: center;
      }
      .desc {
        margin: 30px 0 60px;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        line-height: 1.5;
      }
      .list_wrap {
        display: flex;
        .item {
          flex: 1;
          padding: 22px 32px;
          background-color: #ffffff;
          border-radius: 5px;
          box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
          cursor: pointer;
          transition: .3s all linear;
          &:hover {
            transform: scale(1.2);
          }
          &+.item {
            margin-left: 40px;
          }
          .top {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .item_title {
              margin-bottom:18px;
              font-size: 22px;
              font-weight: bold;
            }
            .text {
              color: #999999;
              font-size: 12px;
            }
            .photo {
              width: 33px;
            }
          }
          .texts {
            margin-top: 8px;
            color: #999999;
            font-size: 12px
          }
        }
      }
    }
  }
  .consult_box {
    padding: 70px 0 112px;
    .consult_inner {
      .title {
        font-size: 36px;
        font-weight: bold;
        text-align: center;
      }
      .all {
        color: #999999;
        font-size: 20px;
        text-align: center;
        font-weight: 500;
        margin-top: 20px;
        cursor: pointer;
      }
      .desc {
        margin: 30px 0 60px;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        line-height: 1.5;
      }
    }
    .certify {
      position: relative;
      width: 1200px;
      margin: 0 auto;
      .swiper-container {
        padding-bottom: 60px;
      }
      .swiper-slide {
        width: 520px;
        height: 408px;
        background: #fff;
        box-shadow: 0 8px 30px #ddd;
      }
      .swiper-slide img{
        display: block;
      }
      .swiper-slide p {
        line-height: 98px;
        padding-top: 0;
        text-align: center;
        color: #636363;
        font-size: 1.1em;
        margin: 0;
      }
    }
    .content_wrap {
      position: relative;
      width: 100%;
      text-align: center;
      .name {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .text {
        font-size: 16px;
        line-height: 1.5;
      }
    }
    .swiper-slide {
      height: 422px;
    }
  }
  .curriculum_box {
    padding: 70px 0 112px;
    border-top: 20px solid #f5f5f5;
    .consult_inner {
      .title {
        font-size: 36px;
        font-weight: bold;
        text-align: center;
      }
      .desc {
        margin: 30px 0 60px;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        line-height: 1.5;
      }
      .curriculum {
        overflow: hidden;
        width: 100%;
        .list_wrap {
          display: flex;
          flex-wrap: wrap;
          .item {
            display: flex;
            flex-direction: column;
            flex: 0 0 285px;
            margin-bottom: 20px;
            .photo {
              display: block;
              width: 100%;
              height: 200px;
            }
            .content_wrap {
              padding: 15px 8px;
              box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
              .content {
                box-sizing: border-box;
                height: 32px;
                line-height: 1.3;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
				color: #333;
              }
            }
            
            &:not(:nth-child(4n+1)) {
              margin-left: 20px;
            }
          }
        }
        
      }
      .swiper_pagination {
        display: flex;
        justify-content: center;
        height: 16px;
        .item {
          width: 16px;
          height: 16px;
          background-color: #DCDCDC;
          border-radius: 50%;
          cursor: pointer;
          &.active {
            background-color: #E51418;
          }
          &+.item {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .consulting_service_box {
    padding: 70px 0 112px;
    border-top: 20px solid #f5f5f5;
    background: url('../assets/images/home_photo7.png') no-repeat center center;
    background-size: 100% 100%;
    .consulting_service_inner {
      .title {
        color: #ffffff;
        font-size: 36px;
        font-weight: bold;
        text-align: center;
      }
      .desc {
        margin: 30px 0 60px;
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        line-height: 1.5;
      }
      .list_wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon {
          width: 68px;
          height: 68px;
          border-radius: 50%;
          text-align: center;
          line-height: 68px;
          color: #fa5a5b;
          font-size: 32px;
          font-weight: bold;
          background: #ffffff url('../assets/images/home_photo8.png') no-repeat center center;
          background-size: 32px 32px;
        }
        .item {
          box-sizing: border-box;
          width: 320px;
          height: 360px;
          padding: 36px 30px 0;
          color: #333333;
          background-color: #ffffff;
          border-radius: 5px;
         
          .label {
            margin-bottom: 30px;
            font-size: 28px;
            font-weight: 500;
            text-align: center;
          }
          .text {
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: 400;
            cursor: pointer;
            &::before {
              content: "";
              width: 8px;
              height: 8px;
              margin-right: 8px;
              border-radius: 50%;
              background-color: #E51418;
            }
            &+.text {
              margin-top: 24px;
            }
          }
        }
      }
    }
  }
  .card_box {
    padding: 40px 0 70px;
    .card_inner {
      display: flex;
      align-items: center;
      .card_item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        .photo {
          width: 160px;
          height: 160px;
        }
        .title {
          margin: 25px 0 16px;
          font-size: 28px;
          font-weight: bold;
          text-align: center;
        }
        .content {
          width: 160px;
          color: #666666;
          font-size: 14px;
          text-align: justify;
          line-height: 1.5;
        }
      }
    }
  }
  .dowload_box {
    width: 100%;
    height: 782px;
    background: url('../assets/images/home_photo14.png') no-repeat center center;
    background-size: 100% 100%;
    .download_inner {
      display: flex;
      .photo1 {
        width: 281px;
        height: 513px;
        margin-left: 70px;
        margin-top: 36px;
      }
      .photo2 {
        position: relative;
        top: 126px;
        width: 318px;
        height: 579px;
        margin-left: -70px;
      }
      .code_wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 782px;
        .wrap {
          display: flex;
          .item {
            margin-right: 55px;
            text-align: center;
            img {
              width: 233px;
              height: 233px;
            }
            .text {
              margin-top: 21px;
              font-size: 24px;
            }
          }
        }
        .title {
          font-size: 40px;
          font-weight: 500;
        }
        .desc {
          margin: 25px 0 50px;
          color: #666666;
          font-size: 24px;
        }
        
      }
    }
  }
  .partners_box {
    padding: 70px 0 112px;
    border-top: 20px solid #f5f5f5;
    .partners_inner {
      .title {
        font-size: 36px;
        font-weight: bold;
        text-align: center;
      }
      .desc {
        margin: 30px 0 60px;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        line-height: 1.5;
      }
      .list_wrap {
        display: flex;
        flex-wrap: wrap;
        .photo {
          width: 240px;
          height: 120px;
          margin-bottom: 60px;
          &:not(:nth-child(4n+1)) {
            margin-left: 80px;
          }
		  img{
			  width: 240px;
			  height: 120px;
		  }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .home_container {
    .banber_swiper {
      display: block;
      width: 100%;
      height: 180px!important;
	  .banber{
	  		width: 100%;
	  		height: 180px;
	  }
	  .width1200{
		  width: 100%;
		  margin-top: -150px;
		  .fixed{
			  width: 50%;
			  right: 5%;
			  h1{
				  font-size: 14px;
				  margin-top: 10px;
				  font-weight: normal;
			  }
			  h2{
				font-weight: normal;
			  	font-size: 12px;
			  }
			  .btnlist{
				  .btn{
					  margin-top: 10px;
				  }
			  }
		  }
	  }
      img {
        width: 100%;
        height: 180px;
      }
      .swiper-container {
        position: relative;
      }
    }
    .service_box {
      padding: 32px 0 32px;
      background-color: #f5f5f5;
      .service_inner {
        .title {
          font-size: 24px;
          font-weight: bold;
          text-align: center;
        }
        .desc {
          margin: 15px 12px 30px;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          line-height: 1.5;
          white-space: normal;
        }
        .list_wrap {
          display: flex;
          flex-direction: column;
          padding: 0 12px;
          .item {
            flex: 1;
            padding: 22px 32px;
            background-color: #ffffff;
            border-radius: 5px;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
            cursor: pointer;
            transition: .3s all linear;
            &:hover {
              transform: scale(1);
            }
            &+.item {
              margin-left: 0;
              margin-top: 12px;
            }
            .top {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              .item_title {
                margin-bottom:18px;
                font-size: 22px;
                font-weight: bold;
              }
              .text {
                color: #999999;
                font-size: 12px;
              }
              .photo {
                width: 33px;
              }
            }
            .texts {
              margin-top: 8px;
              color: #999999;
              font-size: 12px
            }
          }
        }
      }
    }
    .consult_box {
      padding: 32px 0 32px;
      .consult_inner {
        .title {
          font-size: 24px;
          font-weight: bold;
          text-align: center;
        }
        .desc {
          margin: 15px 12px 30px;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          line-height: 1.5;
          white-space: normal;
        }
      }
      .certify {
        position: relative;
        width: 90%;
        margin: 0 auto;
        .swiper-container {
          padding-bottom: 30px;
        }
        .swiper-slide {
          width: 100%;
          height: 160px;
          background: transparent;
          box-shadow: none;
          img {
            width: 70%;
            height: 100%;
          }
        }
        .swiper-slide img{
          display: block;
          margin: 0 auto;
        }
        .swiper-slide p {
          line-height: 98px;
          padding-top: 0;
          text-align: center;
          color: #636363;
          font-size: 1.1em;
          margin: 0;
        }
      }
      .content_wrap {
        position: relative;
        width: 100%;
        text-align: center;
        .name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .text {
          font-size: 12px;
          line-height: 1.5;
        }
      }
    }
    .curriculum_box {
      padding: 32px 0 32px;
      border-top: 20px solid #f5f5f5;
      .consult_inner {
        .title {
          font-size: 24px;
          font-weight: bold;
          text-align: center;
        }
        .desc {
          margin: 15px 12px 30px;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          line-height: 1.5;
          white-space: normal;
        }
        .curriculum {
          overflow: hidden;
          width: 100%;
          .list_wrap {
            display: flex;
            flex-wrap: wrap;
            margin: 0 15px;
            .item {
              display: flex;
              flex-direction: column;
              flex: 0 0 calc(50% - 6px);
              margin-bottom: 20px;
              &:nth-child(odd) {
                margin-right: 12px;
              }
              .photo {
                display: block;
                width: 100%;
                height: 100px;
              }
              .content_wrap {
                padding: 15px 8px;
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
                .content {
                  box-sizing: border-box;
                  height: 32px;
                  line-height: 1.3;
                  display: -webkit-box;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
				  color: #333;
                }
              }
              
              &:not(:nth-child(4n+1)) {
                margin-left: 0;
              }
            }
          }
          
        }
        .swiper_pagination {
          display: flex;
          justify-content: center;
          height: 8px;
          .item {
            width: 8px;
            height: 8px;
            background-color: #DCDCDC;
            border-radius: 50%;
            cursor: pointer;
            &.active {
              background-color: #E51418;
            }
            &+.item {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .consulting_service_box {
      padding: 32px 0;
      border-top: 20px solid #f5f5f5;
      background: url('../assets/images/home_photo7.png') no-repeat center center;
      background-size: 100% 100%;
      .consulting_service_inner {
        .title {
          font-size: 24px;
          font-weight: bold;
          text-align: center;
        }
        .desc {
          margin: 15px 12px 30px;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          line-height: 1.5;
          white-space: normal;
        }
        .list_wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          margin: 0 15px;
          .icon {
            display: none;
            width: 68px;
            height: 68px;
            border-radius: 50%;
            text-align: center;
            line-height: 68px;
            color: #fa5a5b;
            font-size: 32px;
            font-weight: bold;
            background: #ffffff url('../assets/images/home_photo8.png') no-repeat center center;
            background-size: 32px 32px;
          }
          .item {
            box-sizing: border-box;
            width: 100%;
            height: auto;
            padding: 15px 15px;
            color: #333333;
            background-color: #ffffff;
            border-radius: 5px;
            &:not(:first-child) {
              margin-top: 12px;
            }
            .label {
              margin-bottom: 15px;
              font-size: 20px;
              font-weight: bold;
              text-align: center;
            }
            .text {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-weight: 400;
              cursor: pointer;
              &::before {
                content: "";
                width: 6px;
                height: 6px;
                margin-right: 6px;
                border-radius: 50%;
                background-color: #E51418;
              }
              &+.text {
                margin-top: 24px;
              }
            }
          }
        }
      }
    }
    .card_box {
      padding: 32px 0;
      .card_inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 15px;
        .card_item {
          flex: 0 0 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 20px;
          .photo {
            width: 120px;
            height: 120px;
          }
          .title {
            margin: 25px 0 16px;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
          }
          .content {
            color: #666666;
            font-size: 14px;
            text-align: justify;
            line-height: 1.5;
          }
        }
      }
    }
    .dowload_box {
      width: 100%;
      height: 330px;
      background: url('../assets/images/home_photo14.png') no-repeat center center;
      background-size: 100% 100%;
      .download_inner {
        display: flex;
        .photo1 {
          width: 120px;
          height: 240px;
          margin-left: 0;
          margin-top: 36px;
        }
        .photo2 {
          position: relative;
          top: 70px;
          width: 142px;
          height: 260px;
          margin-left: -32px;
        }
        .code_wrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 330px;
          .wrap {
            display: flex;
            .item {
              margin-right: 12px;
              text-align: center;
              img {
                width: 80px;
                height: 80px;
              }
              .text {
                margin-top: 21px;
                font-size: 12px;
              }
            }
          }
          .title {
            font-size: 20px;
            font-weight: bold;
          }
          .desc {
            margin: 12px 0 20px;
            color: #666666;
            font-size: 14px;
          }
        }
      }
    }
    .partners_box {
      padding: 32px 0;
      border-top: 0 solid #f5f5f5;
      .partners_inner {
        .title {
          font-size: 24px;
          font-weight: bold;
          text-align: center;
        }
        .desc {
          margin: 15px 12px 30px;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          line-height: 1.5;
          white-space: normal;
        }
        .list_wrap {
          display: flex;
          flex-wrap: wrap;
          .photo {
            width: 50%;
            height: 100px;
            margin-bottom: 12px;
            &:not(:nth-child(4n+1)) {
              margin-left: 0px;
            }
			img{
				width: 100%;
				height: 100%;
			}
          }
        }
      }
    }
  }
}
</style>
